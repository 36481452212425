import React, { useState, useEffect, useMemo } from "react";
import useGetApi from "../../hooks/useGetApi";
import Table from "../general/Table";
import styled from "styled-components";
import { Avatar, Button } from "@mui/material";
import { ClassNames } from "@emotion/react";
import TimeIcon from "../../assets/images/timeCont.svg";
import TickSquare from "../../assets/images/tick-square.svg";
import { useDispatch } from "react-redux";
import { updateActiveMenu } from "../store/reducers/AdminReducer";
import PaymentRequestModal from "../includes/Payment/Modals/PaymentRequestModal";
import axiosInstance from "../../api";
import { API_BASE_URL } from "../../constants";

const PaymentRequest = () => {
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [alert, setAlert] = useState({
        visible: false,
        message: "",
        type: "",
    });
    const filterData = [
        {
            id: 1,
            name: "All",
        },
        {
            id: 2,
            name: "Pending",
        },
        {
            id: 3,
            name: "Completed",
        },
    ];
    const [activeFilter, setActiveFilter] = useState({ id: 1, name: "All" });

    useEffect(() => {
        dispatch(updateActiveMenu("Payment Request"));
    }, []);

    const { data, refetch, loading } = useGetApi("users/admin/vendors/", {
        page,
    });
    const [paymetRequest, setPaymetRequest] = useState(data);
    useEffect(() => {
        if (data) {
            setPaymetRequest(data);
        }
    }, [data]);
    useEffect(() => {
        if (paymetRequest?.count) {
            setPageCount(Math.ceil(paymetRequest.count / 10));
        }
    }, [paymetRequest]);

    const filtertedData = useMemo(() => {
        if (activeFilter.name === "all") {
            return paymetRequest?.results || [];
        }
        if (activeFilter.name === "Pending") {
            return (paymetRequest?.results || []).filter(
                (payment) => payment.status === "inactive"
            );
        }
        return (paymetRequest?.results || []).filter(
            (payment) => payment.status === activeFilter.name.toLowerCase()
        );
    }, [activeFilter, paymetRequest]);

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: "#FFF199",
            },
            children: `${name
                ?.split(" ")
                .map((n) => n[0])
                .slice(0, 2)
                .join("")}`,
        };
    }

    const columns = useMemo(() => {
        let baseColumns = [
            {
                Header: "Sl No",
                accessor: "id",
                Cell: ({ cell }) => {
                    return <span>{cell.row.index + 1}</span>;
                },
            },
            {
                Header: "Vendor ID",
                accessor: "auto_id",
                Cell: ({ cell }) => {
                    return <span>{"#" + cell.value}</span>;
                },
            },
            {
                Header: "Vendor Details",
                accessor: "name",
                Cell: ({ cell }) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Avatar {...stringAvatar(cell.row.original.name)} />
                            <div>
                                <h4
                                    style={{
                                        fontSize: "18px",
                                        color: "#000",
                                        fontWeight: 500,
                                    }}
                                >
                                    {cell.row.original.name}
                                </h4>
                                <h5
                                    style={{
                                        fontSize: "16px",
                                        color: "#808080",
                                        fontWeight: 400,
                                    }}
                                >
                                    {cell.row.original.phone}
                                </h5>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: ({ cell }) => {
                    return <span>r{cell.row.amount}</span>;
                },
            },
            {
                Header: "Date",
                accessor: "date",
                Cell: ({ cell }) => {
                    return <span>r{cell.row.date}</span>;
                },
            },
        ];
        if (activeFilter.name === "All") {
            baseColumns = [
                ...baseColumns,
                {
                    Header: "Status",
                    accessor: "sta",
                    Cell: ({ cell }) => {
                        const status = cell.row.original.status;
                        return status === "active" ? (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#EAF8F2",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    width: "120px",
                                    color: "#009262",
                                }}
                            >
                                {/* <img src={TickSquare} alt="" /> */}
                                active
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    background: "#FFFFF0",
                                    padding: "10px 15px",
                                    borderRadius: "5px",
                                    width: "120px",
                                    color: "#FFB240",
                                }}
                            >
                                {/* <img src={TimeIcon} alt="" /> */}
                                inactive
                            </div>
                        );
                    },
                },
                {
                    Header: "Action",
                    accessor: "status",
                    Cell: ({ cell: { value }, cell }) => {
                        const activateUser = () => {
                            axiosInstance
                                .post(
                                    API_BASE_URL +
                                        `users/admin/vendor/activate/${cell.row.original.id}/`
                                )
                                .then((res) => {
                                    const { StatusCode } = res.data;
                                    if (StatusCode === 6000) {
                                        const updatedPayments =
                                            paymetRequest.results.map(
                                                (payment) =>
                                                    payment.id ===
                                                    cell.row.original.id
                                                        ? {
                                                              ...payment,
                                                              status: "active",
                                                          }
                                                        : payment
                                            );
                                        setPaymetRequest({
                                            ...paymetRequest,
                                            results: updatedPayments,
                                        });
                                        setAlert({
                                            visible: true,
                                            message: "Activated ",
                                            type: "success",
                                        });
                                        setTimeout(() => {
                                            setAlert({
                                                visible: false,
                                                message: "",
                                                type: "",
                                            });
                                        }, 3000);
                                    }
                                })

                                .catch((error) => {
                                    setAlert({
                                        visible: true,
                                        message:
                                            "Failed to activate payment. Please try again.",
                                        type: "error",
                                    });
                                    setTimeout(() => {
                                        setAlert({
                                            visible: false,
                                            message: "",
                                            type: "",
                                        });
                                    }, 3000);
                                });
                        };
                        const deActivateUser = () => {
                            axiosInstance
                                .post(
                                    API_BASE_URL +
                                        `users/admin/vendor/deactivate/${cell.row.original.id}/`
                                )
                                .then((res) => {
                                    const { StatusCode } = res.data;

                                    if (StatusCode === 6000) {
                                        setpaymetRequest((prevData) => {
                                            const updatedPayments =
                                                prevData.results.map(
                                                    (payment) =>
                                                        payment.id ===
                                                        cell.row.original.id
                                                            ? {
                                                                  ...payment,
                                                                  status: "inactive",
                                                              }
                                                            : payment
                                                );
                                            return {
                                                ...prevData,
                                                results: updatedPayments,
                                            };
                                        });
                                        setAlert({
                                            visible: true,
                                            message: "Deactivated",
                                            type: "success",
                                        });

                                        setTimeout(() => {
                                            setAlert({
                                                visible: false,
                                                message: "",
                                                type: "",
                                            });
                                        }, 3000);
                                    }
                                })
                                .catch((err) => {
                                    setAlert({
                                        visible: true,
                                        message:
                                            "Failed to deactivate vendor. Please try again.",
                                        type: "error",
                                    });
                                    setTimeout(() => {
                                        setAlert({
                                            visible: false,
                                            message: "",
                                            type: "",
                                        });
                                    }, 3000);
                                });
                        };

                        return value === "active" ? (
                            <Button
                                sx={{
                                    border: "2px solid #EAB600",
                                    color: "#EAB600",
                                    padding: "8px 20px",
                                    width: "150px",
                                    textTransform: "capitalize",
                                }}
                                onClick={() => deActivateUser()}
                            >
                                Deactivate
                            </Button>
                        ) : (
                            <Button
                                sx={{
                                    background:
                                        "linear-gradient(180deg, #FFF79E 0%, #FFDF17 100%)",
                                    color: "#000",
                                    padding: "8px 20px",
                                    width: "150px",
                                    textTransform: "capitalize",
                                }}
                                onClick={() => {
                                    activateUser();
                                }}
                            >
                                Activate
                            </Button>
                        );
                    },
                },
            ];
        }
        return baseColumns;
    }, [activeFilter, paymetRequest]);

    // useEffect(() => {
    //     if (paymetRequest?.count) {
    //         setPageCount(Math.ceil(paymetRequest.count / 10)); // Assuming page size is 10
    //     }
    // }, [paymetRequest]);

    return (
        <div>
            <TopContainer>
                {filterData.map((item) => (
                    <Button
                        key={item.id}
                        onClick={() => setActiveFilter(item)}
                        sx={{
                            backgroundColor:
                                activeFilter.id === item.id ? "#FFF2A1" : null,
                            color: "#000",
                        }}
                        style={{ textTransform: "capitalize" }}
                    >
                        {item.name}
                    </Button>
                ))}
            </TopContainer>
            <Table
                columns={columns}
                data={filtertedData}
                fetchData={refetch}
                loading={loading}
                pageCount={pageCount}
                totalPages={pageCount}
                setPage={setPage}
                currentPage={page}
                PaymentRequest={paymetRequest}
                setPaymetRequest={setPaymetRequest}
                alert={alert}
            />
        </div>
    );
};

export default PaymentRequest;

const TopContainer = styled.div`
    width: 100%;
    padding: 20px 20px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 8px;
    gap: 20px;
`;
