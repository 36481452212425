import React, { useState, useEffect, useMemo } from "react";
import useGetApi from "../../hooks/useGetApi";
import Table from "../general/Table";
import styled from "styled-components";
import { Avatar, Button } from "@mui/material";
import { ClassNames } from "@emotion/react";
import TimeIcon from "../../assets/images/timeCont.svg";
import TickSquare from "../../assets/images/tick-square.svg";
import axiosInstance from "../../api";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch } from "react-redux";
import { updateActiveMenu } from "../store/reducers/AdminReducer";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const VendorOrders = () => {
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [popoverData, setPopoverData] = useState({
        isVisible: false,
        position: { top: 0, left: 20 },
    });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateActiveMenu("Vendor Orders"));
    }, []);
    const filterData = [
        {
            id: 1,
            name: "All",
        },
        {
            id: 2,
            name: "Pending",
        },
        {
            id: 3,
            name: "Active",
        },
        {
            id: 4,
            name: "Inactive",
        },
        {
            id: 5,
            name: "Rejected",
        },
    ];
    const [activeFilter, setActiveFilter] = useState({ id: 1, name: "All" });
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: "#FFF199",
            },
            children: `${name
                ?.split(" ")
                .map((n) => n[0])
                .slice(0, 2)
                .join("")}`,
        };
    }
    const handlePopoverOpen = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverData({
            isVisible: true,
            position: {
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            },
        });
    };
    const handlePopoverClose = () => {
        setPopoverData({ isVisible: false, position: { top: 0, left: 0 } });
    };
    const columns = useMemo(
        () => [
            {
                Header: "Sl No",
                accessor: "id",
                Cell: ({ cell }) => {
                    console.log(cell.row.index);
                    return <span>{cell.row.index + 1}</span>;
                },
            },
            {
                Header: "Product ID",
                accessor: "auto_id",
                Cell: ({ cell }) => {
                    console.log(cell.row.index);
                    return <span>{"#" + cell.value}</span>;
                },
            },
            {
                Header: "Product Detail",
                accessor: "auto_ids",
                Cell: ({ cell }) => {
                    console.log(cell.row.index);
                    return <span>{"#" + cell.value}</span>;
                },
            },
            {
                Header: "Vendor Detail",
                accessor: "name",
                Cell: ({ cell }) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Avatar {...stringAvatar(cell.row.original.name)} />
                            <div>
                                <h4
                                    style={{
                                        fontSize: "18px",
                                        color: "#000",
                                        fontWeight: 500,
                                    }}
                                >
                                    {cell.row.original.name}
                                </h4>
                                <h5
                                    style={{
                                        fontSize: "16px",
                                        color: "#808080",
                                        fontWeight: 400,
                                    }}
                                >
                                    {cell.row.original.phone}
                                </h5>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: "Date",
                accessor: "date",
                Cell: ({ cell }) => {
                    console.log(cell.row.index);
                    return <span>{"#" + cell.value}</span>;
                },
            },
            {
                Header: "Status",
                accessor: "sta",
                Cell: ({ cell }) => {
                    const status = cell.row.original.status;

                    return status === "active" ? (
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                background: "#EAF8F2",
                                padding: "10px 15px",
                                borderRadius: "5px",
                                width: "120px",
                                color: "#009262",
                            }}
                        >
                            <img src={TickSquare} alt="" />
                            active
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                background: "#FFFFF0",
                                padding: "10px 15px",
                                borderRadius: "5px",
                                width: "120px",
                                color: "#FFB240",
                            }}
                        >
                            <img src={TimeIcon} alt="" />
                            inactive
                        </div>
                    );
                },
            },
            {
                Header: "Action",
                accessor: "status",
                Cell: () => {
                    return (
                        <>
                            {" "}
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlePopoverOpen(e);
                                }}
                            >
                                <MoreHorizIcon />
                            </Button>
                            {popoverData.isVisible && (
                                <div
                                    className="custom"
                                    style={{
                                        top: `${popoverData.position.top}px`,
                                        left: `${popoverData.position.left}px`,
                                    }}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    <div
                                        className="popover-item popover-item-green"
                                        onClick={() =>
                                            console.log("Vendor Verified")
                                        }
                                    >
                                        ✅ Vendor Verification
                                    </div>
                                    <div
                                        className="popover-item popover-item-red"
                                        onClick={() =>
                                            console.log("Vendor Rejected")
                                        }
                                    >
                                        ❌ Vendor Rejection
                                    </div>
                                </div>
                            )}
                        </>
                    );
                },
            },
        ],
        []
    );

    const {
        data: vendorData,
        refetch,
        loading,
    } = useGetApi("shops/admin/inventory/", { page: page });
    console.log(vendorData?.count, "___count");
    useEffect(() => {
        if (vendorData?.count) {
            setPageCount(Math.ceil(vendorData.count / 10)); // Assuming page size is 10
        }
    }, [vendorData]);

    return (
        <div>
            <TopContainer>
                {filterData.map((item) => (
                    <Button
                        onClick={() => setActiveFilter(item)}
                        sx={{
                            backgroundColor:
                                activeFilter.id === item.id ? "#FFF2A1" : null,
                            color: "#000",
                        }}
                        style={{ textTransform: "capitalize" }}
                    >
                        {item.name}
                    </Button>
                ))}
            </TopContainer>
            <Table
                columns={columns}
                data={vendorData?.results || []}
                fetchData={refetch}
                loading={loading}
                pageCount={vendorData?.count}
                totalPages={vendorData?.count % 10}
                setPage={setPage}
                currentPage={page}
            />
        </div>
    );
};

export default VendorOrders;

const TopContainer = styled.div`
    width: 100%;
    padding: 20px 20px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 8px;
    gap: 20px;
`;
