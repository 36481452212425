import React, { useState, useEffect, useMemo } from "react";
import useGetApi from "../../hooks/useGetApi";
import Table from "../general/Table";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { updateActiveMenu } from "../store/reducers/AdminReducer";
import { Avatar, Button } from "@mui/material";

import TickSquare from "../../assets/images/tick-square.svg";
import "../../assets/css/VendorProduct.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const VendorProduct = () => {
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const [popoverData, setPopoverData] = useState({
        isVisible: false,
        position: { top: 0, left: 20 },
    });
    const filterData = [
        { id: 1, name: "All" },
        { id: 2, name: "Pending" },
        { id: 3, name: "Active" },
        { id: 4, name: "Inactive" },
        { id: 5, name: "Rejected" },
    ];
    const [activeFilter, setActiveFilter] = useState({ id: 1, name: "All" });

    // Update menu name
    useEffect(() => {
        dispatch(updateActiveMenu("Vendors Products"));
    }, [dispatch]);

    // Fetch API data
    const {
        data: vendorData,
        refetch,
        loading,
    } = useGetApi("shops/admin/inventory/", { page });

    useEffect(() => {
        if (vendorData?.count) {
            setPageCount(Math.ceil(vendorData.count / 10)); // Assuming page size is 10
        }
    }, [vendorData]);

    // Filter data based on activeFilter
    const filteredData = useMemo(() => {
        if (activeFilter.name === "All") {
            return vendorData?.results || [];
        }
        if (activeFilter.name === "Inactive") {
            return (vendorData?.results || []).filter(
                (vendor) =>
                    vendor.status === "inactive" || vendor.status === "pending"
            );
        }
        return (vendorData?.results || []).filter(
            (vendor) => vendor.status === activeFilter.name.toLowerCase()
        );
    }, [activeFilter, vendorData]);

    const handlePopoverOpen = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverData({
            isVisible: true,
            position: {
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            },
        });
    };

    const handlePopoverClose = () => {
        setPopoverData({ isVisible: false, position: { top: 0, left: 0 } });
    };
    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: "#FFF199",
            },
            children: `${name
                ?.split(" ")
                .map((n) => n[0])
                .slice(0, 2)
                .join("")}`,
        };
    }
    const columns = useMemo(
        () => [
            {
                Header: "Sl No",
                accessor: "id",
                Cell: ({ cell }) => <span>{cell.row.index + 1}</span>,
            },
            {
                Header: "Product ID",
                accessor: "auto_id",
                Cell: ({ cell }) => <span>{"#" + cell.value}</span>,
            },
            {
                Header: "Product Detail",
                accessor: "auto_ids",
                Cell: ({ cell }) => <span>{"#" + cell.value}</span>,
            },
            {
                Header: "Vendor Detail",
                accessor: "name",
                Cell: ({ cell }) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        <Avatar {...stringAvatar(cell.row.original.name)} />
                        <div>
                            <h4
                                style={{
                                    fontSize: "18px",
                                    color: "#000",
                                    fontWeight: 500,
                                }}
                            >
                                {cell.row.original.name}
                            </h4>
                            <h5
                                style={{
                                    fontSize: "16px",
                                    color: "#808080",
                                    fontWeight: 400,
                                }}
                            >
                                {cell.row.original.phone}
                            </h5>
                        </div>
                    </div>
                ),
            },
            {
                Header: "Date",
                accessor: "date",
                Cell: ({ cell }) => <span>{cell.value}</span>,
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ cell }) => {
                    const status = cell.row.original.status;
                    return status === "active" ? (
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                background: "#EAF8F2",
                                padding: "10px 15px",
                                borderRadius: "5px",
                                width: "120px",
                                color: "#009262",
                            }}
                        >
                            {/* <img src={TickSquare} alt="" /> */}
                            active
                        </div>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                background: "#FFFFF0",
                                padding: "10px 15px",
                                borderRadius: "5px",
                                width: "120px",
                                color: "#FFB240",
                            }}
                        >
                            {/* <img src={TimeIcon} alt="" /> */}
                            inactive
                        </div>
                    );
                },
            },
            {
                Header: "Action",
                accessor: "actions",
                Cell: () => (
                    <>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                handlePopoverOpen(e);
                            }}
                        >
                            <MoreHorizIcon />
                        </Button>
                        {popoverData.isVisible && (
                            <div
                                className="custom-popover"
                                style={{
                                    top: `${popoverData.position.top}px`,
                                    left: `${popoverData.position.left}px`,
                                }}
                                onMouseLeave={handlePopoverClose}
                            >
                                <div
                                    className="popover-item popover-item-green"
                                    onClick={() =>
                                        console.log("Vendor Verified")
                                    }
                                >
                                    ✅ Vendor Verification
                                </div>
                                <div
                                    className="popover-item popover-item-red"
                                    onClick={() =>
                                        console.log("Vendor Rejected")
                                    }
                                >
                                    ❌ Vendor Rejection
                                </div>
                            </div>
                        )}
                    </>
                ),
            },
        ],
        [popoverData]
    );

    return (
        <div>
            <TopContainer>
                {filterData.map((item) => (
                    <button
                        key={item.id}
                        onClick={() => setActiveFilter(item)}
                        className={`filter-button ${
                            activeFilter.id === item.id ? "active-filter" : ""
                        }`}
                    >
                        {item.name}
                    </button>
                ))}
            </TopContainer>
            <Table
                columns={columns}
                data={filteredData}
                fetchData={refetch}
                loading={loading}
                pageCount={pageCount}
                totalPages={pageCount}
                setPage={setPage}
                currentPage={page}
            />
        </div>
    );
};

export default VendorProduct;

const TopContainer = styled.div`
    width: 100%;
    padding: 20px 20px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 8px;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
`;
